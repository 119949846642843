<template>
  <section class="content">
    <div class="error-page">
      <h2 class="headline text-warning"> 404</h2>

      <div class="error-content">
        <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found or you no access.</h3>

        <p>
          We could not find the page you were looking for or you no access
          Meanwhile, you may
          <router-link to="/home" class="nav-link">return to dashboard</router-link>
        </p>
      </div>
      <!-- /.error-content -->
    </div>
    <!-- /.error-page -->
  </section>
</template>

<script>
export default {
  methods:{

  }
}
</script>
